
import { defineComponent } from "vue";
import ClearanceCard from "@/components/users/ClearanceCard.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { Navigation, Store } from "@/controllers/common";
import { Clearance, FirebaseUser, UserData } from "@/models/users";
import UsersController from "@/controllers/users";

import Authentication from "@/controllers/auth";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "EditUser",
  setup() {
    useMeta({
      title: "Cambiar Autorización",
      description:
        "Cambia el nivel de acceso de un usuario de CMS Centro Cristiano.",
    });
  },
  components: {
    ClearanceCard,
    BaseButton,
  },
  beforeMount() {
    var user = Store.getValue("edition/user") as UserData;
    if (user) {
      this.data = user;
    } else {
      Navigation.goTo("Users");
    }
  },
  beforeUnmount() {
    Store.update("edition/storeUser", undefined);
  },
  data() {
    return {
      spinnerState: false,
      data: {} as UserData,
    };
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    changeClearance(role: string) {
      let clearance: Clearance;
      switch (role) {
        case "finance":
          clearance = Clearance.Finance;
          break;
        case "editor":
          clearance = Clearance.Editor;
          break;
        case "manager":
          clearance = Clearance.Manager;
          break;
        case "administrator":
          clearance = Clearance.Admin;
          break;
        default:
          clearance = Clearance.Registered;
          break;
      }
      this.data.role = clearance;
    },
    async updateClearance(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.updateClearance(
        {
          email: this.data.email,
          clearance: this.data.role ?? Clearance.Registered,
        },
        this.currentUser
      );
      this.spinnerState = false;
    },
    async enableUser(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.disableUser(
        false,
        this.data.email,
        this.currentUser
      );
      this.spinnerState = false;
    },
    async disableUser(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.disableUser(
        true,
        this.data.email,
        this.currentUser
      );
      this.spinnerState = false;
    },
    async deleteUser(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.deleteUser(this.data.email, this.currentUser);
      this.spinnerState = false;
    },
  },
  computed: {
    pictureUrl(): string {
      return this.data.pictureUrl
        ? this.data.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
});
