<template>
  <div class="edit-header">
    <div class="title">
      <div class="action-button users" @click="goBack">
        <img src="@/assets/actions/back-black.svg" alt="Ir a Inicio" />
      </div>
      <h1 class="global-title">Agregar Usuario</h1>
    </div>
  </div>
  <div class="splitted">
    <Form
      v-model="userData"
      :loading="loadingState"
      :forUpdate="false"
      @change-profile="addUser"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Form from "@/components/users/ProfileForm.vue";
import { FirebaseUser, UserData } from "@/models/users";
import { Navigation } from "@/controllers/common";
import Authentication from "@/controllers/auth";

import UsersController from "@/controllers/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "EditUser",
  setup() {
    useMeta({
      title: "Agregar Usuario",
      description: "Agrega un usuario al Staff del CMS Centro Cristiano.",
    });
  },
  components: {
    Form,
  },
  data() {
    return {
      loadingState: false,
      userData: {} as UserData,
    };
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addUser() {
      this.loadingState = true;
      await UsersController.createUser(this.userData, this.currentUser);
      this.loadingState = false;
    },
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
});
</script>

<style scoped>
.profile {
  text-align: center;
}
.profile .user-avatar {
  --avatar-size: 20rem;
}
.profile .name {
  font-size: 2.4rem;
  font-weight: var(--f-semibold);
  margin: 0;
  margin-top: 1.6rem;
}
.clearance-cards {
  column-gap: 4.5rem;
  justify-content: center;
  display: flex;
}
.edit-action {
  text-align: center;
  margin-top: 4rem;
}
.btn-container {
  width: 48%;
}
</style>
