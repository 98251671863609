<template>
  <BaseCard class="container" :class="{ active: active }">
    <div class="data">
      <img :src="icon" :alt="name" class="role-icon" />
      <h2 class="role-name">{{ name }}</h2>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "ClearanceCard",
  components: {
    BaseCard,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.container {
  cursor: pointer;
  height: 13rem;
  width: 13rem;
  padding: 10px;
  box-shadow: 0px 0px 6px rgba(95, 87, 87, 0.16);
  align-items: center;
  justify-content: center;
  display: flex;
}
.container.active {
  border: 2px solid var(--c-harvest-gold);
}
.data {
  text-align: center;
}
.data .role-icon {
  height: 6rem;
  width: 6rem;
}
.data .role-name {
  color: #6e6e73;
  font-size: 1.4rem;
  font-weight: var(--f-bold);
  margin: 0;
}
</style>
