
import { defineComponent } from "vue";
import Form from "@/components/users/ProfileForm.vue";
import { FirebaseUser, UserData } from "@/models/users";
import { Navigation } from "@/controllers/common";
import Authentication from "@/controllers/auth";

import UsersController from "@/controllers/users";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "EditUser",
  setup() {
    useMeta({
      title: "Agregar Usuario",
      description: "Agrega un usuario al Staff del CMS Centro Cristiano.",
    });
  },
  components: {
    Form,
  },
  data() {
    return {
      loadingState: false,
      userData: {} as UserData,
    };
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    async addUser() {
      this.loadingState = true;
      await UsersController.createUser(this.userData, this.currentUser);
      this.loadingState = false;
    },
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
});
