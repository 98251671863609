<template>
  <BaseCard :actionable="true" @click="editUser">
    <div>
      <div class="user-data">
        <img :src="pictureUrl" :alt="fullname" class="user-avatar" />
        <div class="user-info">
          <h3 class="name">{{ fullname }}</h3>
          <h3 class="email">{{ data.email }}</h3>
          <h3 class="phone">{{ formatedPhone }}</h3>
        </div>
      </div>
      <h3 class="clearance">{{ clearance }}{{ userState }}</h3>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { Navigation, Store } from "@/controllers/common";
import { UserData } from "@/models/users";
import { ClearanceController } from "@/controllers/clearance";

export default defineComponent({
  name: "UserCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<UserData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  methods: {
    editUser(): void {
      Store.update("edition/storeUser", this.data);
      Navigation.goTo("EditUser");
    },
  },
  computed: {
    fullname(): string {
      return `${this.data.name} ${this.data.lastname}`;
    },
    clearance(): string {
      return ClearanceController.text(this.data.role);
    },
    formatedPhone(): string {
      var phone = this.data.phone.toString().replace("57", "");
      phone = phone.replace(/\D/g, "");
      var match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `${match[1]} ${match[2]} ${match[3]}`;
      }
      return phone;
    },
    pictureUrl(): string {
      return this.data.pictureUrl
        ? this.data.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
    userState(): string {
      return this.data.disabled ? " (Inactivo)" : "";
    },
  },
});
</script>

<style scoped>
.user-avatar {
  --avatar-size: 8rem;
}
.user-data {
  column-gap: 1.2rem;
  display: flex;
}
.user-info {
  margin: auto 0;
  width: calc(100% - 8rem);
}

.user-info > h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-info .name,
.clearance {
  margin: 0;
  color: var(--c-mid-gray);
  font-size: 1.5rem;
  font-weight: var(--f-semibold);
}
.user-info .name {
  margin-bottom: 0.8rem;
}
.user-info .email,
.user-info .phone {
  margin: 0;
  color: var(--c-silver-chalice);
  font-size: 1.4rem;
  font-weight: var(--f-regular);
}
.user-info .email {
  margin-bottom: 0.4rem;
}
.clearance {
  margin-top: 1rem;
  color: #000;
  text-align: center;
}
</style>
