<template>
  <div class="edit-header">
    <div class="title">
      <div class="action-button users" @click="goBack">
        <img src="@/assets/actions/back-black.svg" alt="Ir a Inicio" />
      </div>
      <h1 class="global-title">Editar usuario</h1>
    </div>
    <div class="actions">
      <div
        class="action-button enable"
        @click="enableUser"
        v-if="data.disabled"
      >
        <img src="@/assets/actions/enable-icon.svg" alt="Activar Usuario" />
      </div>
      <div class="action-button disable" @click="disableUser" v-else>
        <img src="@/assets/actions/disable-icon.svg" alt="Desactivar Usuario" />
      </div>
      <a-popconfirm
        placement="leftTop"
        title="¿Está seguro de que desea eliminar este usuario?"
        ok-text="Sí, eliminar usuario"
        cancel-text="No, mantener usuario"
        @confirm="deleteUser"
      >
        <div class="action-button delete">
          <img src="@/assets/actions/delete-icon.svg" alt="Eliminar Usuario" />
        </div>
      </a-popconfirm>
    </div>
  </div>
  <a-spin :spinning="spinnerState" :delay="250">
    <div class="profile">
      <img :src="pictureUrl" alt="Nombre" class="user-avatar" />
      <h2 class="name">{{ data.name }} {{ data.lastname }}</h2>
    </div>
    <h2 class="global-subtitle">Cambiar nivel de autorización:</h2>
    <div class="clearance-cards">
      <ClearanceCard
        :active="data.role == 'registered'"
        :icon="require('@/assets/roles/no-auth.svg')"
        name="Sin Autorización"
        @click="changeClearance('registered')"
      />
      <ClearanceCard
        :active="data.role == 'finance'"
        :icon="require('@/assets/roles/finance.svg')"
        name="Departamento de Finanzas"
        @click="changeClearance('finance')"
      />
      <ClearanceCard
        :active="data.role == 'editor'"
        :icon="require('@/assets/roles/editor.svg')"
        name="Editor de Contenido"
        @click="changeClearance('editor')"
      />
      <ClearanceCard
        :active="data.role == 'manager'"
        :icon="require('@/assets/roles/manager.svg')"
        name="Gestor de Contenido"
        @click="changeClearance('manager')"
      />
      <ClearanceCard
        :active="data.role == 'administrator'"
        :icon="require('@/assets/roles/admin.svg')"
        name="Administrador"
        @click="changeClearance('administrator')"
      />
    </div>
    <div class="edit-action">
      <BaseButton text="Actualizar" @click="updateClearance" />
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClearanceCard from "@/components/users/ClearanceCard.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { Navigation, Store } from "@/controllers/common";
import { Clearance, FirebaseUser, UserData } from "@/models/users";
import UsersController from "@/controllers/users";

import Authentication from "@/controllers/auth";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "EditUser",
  setup() {
    useMeta({
      title: "Cambiar Autorización",
      description:
        "Cambia el nivel de acceso de un usuario de CMS Centro Cristiano.",
    });
  },
  components: {
    ClearanceCard,
    BaseButton,
  },
  beforeMount() {
    var user = Store.getValue("edition/user") as UserData;
    if (user) {
      this.data = user;
    } else {
      Navigation.goTo("Users");
    }
  },
  beforeUnmount() {
    Store.update("edition/storeUser", undefined);
  },
  data() {
    return {
      spinnerState: false,
      data: {} as UserData,
    };
  },
  methods: {
    goBack() {
      Navigation.goBack();
    },
    changeClearance(role: string) {
      let clearance: Clearance;
      switch (role) {
        case "finance":
          clearance = Clearance.Finance;
          break;
        case "editor":
          clearance = Clearance.Editor;
          break;
        case "manager":
          clearance = Clearance.Manager;
          break;
        case "administrator":
          clearance = Clearance.Admin;
          break;
        default:
          clearance = Clearance.Registered;
          break;
      }
      this.data.role = clearance;
    },
    async updateClearance(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.updateClearance(
        {
          email: this.data.email,
          clearance: this.data.role ?? Clearance.Registered,
        },
        this.currentUser
      );
      this.spinnerState = false;
    },
    async enableUser(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.disableUser(
        false,
        this.data.email,
        this.currentUser
      );
      this.spinnerState = false;
    },
    async disableUser(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.disableUser(
        true,
        this.data.email,
        this.currentUser
      );
      this.spinnerState = false;
    },
    async deleteUser(event: Event): Promise<void> {
      event.preventDefault();
      this.spinnerState = true;
      await UsersController.deleteUser(this.data.email, this.currentUser);
      this.spinnerState = false;
    },
  },
  computed: {
    pictureUrl(): string {
      return this.data.pictureUrl
        ? this.data.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
  },
});
</script>

<style scoped>
.profile {
  text-align: center;
}

.profile .user-avatar {
  --avatar-size: 20rem;
}

.profile .name {
  font-size: 2.4rem;
  font-weight: var(--f-semibold);
  margin: 0;
  margin-top: 1.6rem;
}

.clearance-cards {
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}

.edit-action {
  text-align: center;
  margin-top: 4rem;
}

.btn-container {
  width: 90%;
}

@media only screen and (min-width: 768px) {
  .clearance-cards {
    column-gap: 3rem;
    row-gap: 2rem;
  }

  .btn-container {
    width: 60%;
  }
}

@media only screen and (min-width: 1280px) {
  .clearance-cards {
    column-gap: 4.5rem;
    row-gap: 4.5rem;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
  }

  .edit-action {
    text-align: center;
    margin-top: 4rem;
  }

  .btn-container {
    width: 48%;
  }
}
</style>
