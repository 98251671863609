<template>
  <div class="error-cnt">
    <a-empty>
      <template #description>
        <span class="no-data message" v-html="message" />
      </template>
    </a-empty>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Navigation } from "@/controllers/common";

export default defineComponent({
  name: "BaseError",
  props: {
    message: {
      type: String,
      default: "Sin datos",
    },
  },
  methods: {
    goHome(): void {
      Navigation.goTo("Profile");
    },
  },
});
</script>

<style scoped>
.error-cnt {
  min-height: 80vh;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.no-data.message {
  font-family: "Montserrat", sans-serif;
}
</style>
