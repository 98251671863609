
import { defineComponent } from "vue";

import BaseButton from "@/components/common/BaseButton.vue";
import UserCard from "@/components/users/UserCard.vue";

import { Navigation } from "@/controllers/common";
import User, { FirebaseUser, UserData } from "@/models/users";
import UsersController from "@/controllers/users";
import Authentication from "@/controllers/auth";

import BaseError from "@/components/common/BaseError.vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Staff",
  setup() {
    useMeta({
      title: "Staff",
      description: "Usuarios de CMS Centro Cristiano.",
    });
  },
  components: {
    BaseButton,
    UserCard,
    BaseError,
  },
  created() {
    if (this.currentUser) {
      this.fetchingData = true;
      this.emptyResponse = false;
      UsersController.fecthAll(this.currentUser).then((usersData) => {
        this.fetchingData = false;
        if (usersData) {
          const data = usersData as UserData[];
          this.emptyResponse = data.length == 0;
          let users: User[] = [];
          for (const user of data) {
            users.push(new User(user));
          }
          this.users = users;
        }
      });
    }
  },
  data() {
    return {
      emptyResponse: false,
      fetchingData: false,
      users: [] as Array<User>,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    showError(): boolean {
      return this.emptyResponse;
    },
  },
  methods: {
    addUser(): void {
      Navigation.goTo("AddUser");
    },
  },
});
