
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { Navigation, Store } from "@/controllers/common";
import { UserData } from "@/models/users";
import { ClearanceController } from "@/controllers/clearance";

export default defineComponent({
  name: "UserCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<UserData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  methods: {
    editUser(): void {
      Store.update("edition/storeUser", this.data);
      Navigation.goTo("EditUser");
    },
  },
  computed: {
    fullname(): string {
      return `${this.data.name} ${this.data.lastname}`;
    },
    clearance(): string {
      return ClearanceController.text(this.data.role);
    },
    formatedPhone(): string {
      var phone = this.data.phone.toString().replace("57", "");
      phone = phone.replace(/\D/g, "");
      var match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `${match[1]} ${match[2]} ${match[3]}`;
      }
      return phone;
    },
    pictureUrl(): string {
      return this.data.pictureUrl
        ? this.data.pictureUrl
        : require("@/assets/common/avatar-placeholder.png");
    },
    userState(): string {
      return this.data.disabled ? " (Inactivo)" : "";
    },
  },
});
