
import { defineComponent } from "vue";
import { Navigation } from "@/controllers/common";

export default defineComponent({
  name: "BaseError",
  props: {
    message: {
      type: String,
      default: "Sin datos",
    },
  },
  methods: {
    goHome(): void {
      Navigation.goTo("Profile");
    },
  },
});
