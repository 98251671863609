<template>
  <div class="staff-title">
    <h1 class="global-title">Staff</h1>
    <BaseButton :isSmall="true" text="Agregar Usuario" @click="addUser" />
  </div>
  <a-spin :spinning="fetchingData">
    <div
      class="staff-cards animated-container"
      :class="{ loading: fetchingData }"
      v-show="!showError"
    >
      <UserCard v-for="(user, index) in users" :key="index" :data="user" />
    </div>
    <BaseError message="No se encontraron usuarios." v-show="showError" />
  </a-spin>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BaseButton from "@/components/common/BaseButton.vue";
import UserCard from "@/components/users/UserCard.vue";

import { Navigation } from "@/controllers/common";
import User, { FirebaseUser, UserData } from "@/models/users";
import UsersController from "@/controllers/users";
import Authentication from "@/controllers/auth";

import BaseError from "@/components/common/BaseError.vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Staff",
  setup() {
    useMeta({
      title: "Staff",
      description: "Usuarios de CMS Centro Cristiano.",
    });
  },
  components: {
    BaseButton,
    UserCard,
    BaseError,
  },
  created() {
    if (this.currentUser) {
      this.fetchingData = true;
      this.emptyResponse = false;
      UsersController.fecthAll(this.currentUser).then((usersData) => {
        this.fetchingData = false;
        if (usersData) {
          const data = usersData as UserData[];
          this.emptyResponse = data.length == 0;
          let users: User[] = [];
          for (const user of data) {
            users.push(new User(user));
          }
          this.users = users;
        }
      });
    }
  },
  data() {
    return {
      emptyResponse: false,
      fetchingData: false,
      users: [] as Array<User>,
    };
  },
  computed: {
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    showError(): boolean {
      return this.emptyResponse;
    },
  },
  methods: {
    addUser(): void {
      Navigation.goTo("AddUser");
    },
  },
});
</script>

<style scoped>
.staff-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.staff-cards {
  row-gap: 2rem;
  display: grid;
}

.staff-cards.loading {
  min-height: 80vh;
}

@media only screen and (min-width: 768px) {
  .staff-cards {
    column-gap: 1.2rem;
    row-gap: 1.2rem;
    grid-template-columns: repeat(2, calc(50% - 0.6rem));
  }
}

@media only screen and (min-width: 1280px) {
  .staff-cards {
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(3, calc(33.33% - 2rem));
  }
}

@media only screen and (min-width: 1800px) {
  .staff-cards {
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(4, calc(25% - 2.25rem));
  }
}
</style>
