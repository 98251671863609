
import { defineComponent } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "ClearanceCard",
  components: {
    BaseCard,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
});
